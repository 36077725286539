/* website: 2489-westerntoyota
 * created at 2019-04-02 10:37 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/organizations/westerntoyota.scss";
@import "../utils/icons.scss";
.widget-sr{
  .calculator-tradeIn{
    .sr-link{
      display: none !important;
    }
  }
}